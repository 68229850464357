<template>
  <section class="elv-accounts-defi-position-container">
    <AccountScreening
      ref="deFiPositionScreeningRef"
      tableType="DefiPosition"
      :exportText="$t('button.exportTreasuryAccount')"
      @onChangeDeFiPositionFilter="onChangeDeFiPositionFilter"
      @onChangeAccountFilter="onChangeDeFiPositionFilter"
    >
    </AccountScreening>

    <div :style="{ height: `calc(100% - 6px)` }">
      <TableList
        ref="processTableRef"
        type="accounts"
        :status="dataStatus"
        groupName="entityAccount"
        :height="`calc(100% - 6px)`"
        :dateTime="deFiPositionScreeningRef?.dateTimeValue"
      />
    </div>
    <UpgradeProjectPlanDialog ref="upgradeProjectPlanDialogRef" />
  </section>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import { $t } from '@/i18n'
import TableList from './components/TableList.vue'
import { useEntityStore } from '@/stores/modules/entity'
import AccountScreening from '../components/Screening.vue'
import { useAccountStore } from '@/stores/modules/accounts'
import { useReportsTableConfigStore } from '@/stores/modules/reports/tableConfig'
import UpgradeProjectPlanDialog from '@/pages/Financials/ProjectSetting/components/UpgradeProjectPlanDialog.vue'

const route = useRoute()

const entityStore = useEntityStore()
const accountStore = useAccountStore()
const tableConfigStore = useReportsTableConfigStore()
const processTableRef = useTemplateRef('processTableRef')
const upgradeProjectPlanDialogRef = useTemplateRef('upgradeProjectPlanDialogRef')
const deFiPositionScreeningRef = useTemplateRef<InstanceType<typeof AccountScreening>>('deFiPositionScreeningRef')

const dataStatus = ref('')

const entityId = computed(() => {
  return route.params?.entityId as string
})

const onChangeDeFiPositionFilter = async () => {
  try {
    const date = deFiPositionScreeningRef.value?.dateTimeValue
    const subGroup = deFiPositionScreeningRef.value?.subGroup
    const group = deFiPositionScreeningRef.value?.group
    const params: any = {
      date,
      group,
      subGroup
    }
    await tableConfigStore.getProcessTableConfig('deFiPosition', [], 'right', params)
    processTableRef.value?.resetList()
  } catch (error: any) {
    console.log(error)
  }
}

watch(
  () => route,
  async () => {
    if (route.name === 'entity-accounts-defi-position') {
      try {
        tableConfigStore.processTableConfig = []
        const deFiPositionFilter: any =
          accountStore.deFiPositionsFilterList.find((item: any) => {
            return item.entityId === entityId.value
          }) ?? {}
        const params = {
          date: dayjs().tz(entityStore.entityDetail?.timezone).subtract(1, 'day').format('YYYY-MM-DD'),
          subGroup: 'NONE',
          group: 'ENTITY_ACCOUNT',
          ...deFiPositionFilter?.data
        }
        await tableConfigStore.getProcessTableConfig('deFiPosition', [], 'right', params)
        nextTick(() => {
          processTableRef.value?.resetList()
        })
      } catch (error) {
        console.log(error)
      }
    }
  },
  { immediate: true, deep: true }
)

onBeforeUnmount(() => {
  tableConfigStore.processTableConfig = []
  accountStore.agGridApi = null
  accountStore.agGridColumnApi = null
})
</script>

<style lang="scss" scoped>
.elv-accounts-defi-position-container {
  width: 100%;
  height: calc(100% - 90px);
  position: relative;
}

:deep(.elv-financial-process-table) {
  padding-top: 0px;
}

.elv-accounts-header-main-right-add {
  position: absolute;
  right: 24px;
  top: -41px;
}
</style>
